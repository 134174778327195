import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          uokin.cn
        </p>
        <p
          className="App-link"
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >
          敬请期待
        </p>
      </header>
      <div style={{position:'absolute',bottom:'0',width:'100%'}}>
        <div style={{color:'white',textAlign:'center',margin:'5px'}}>
        ©2021 珑璟科技  All Rights Reserved  <a style={{color: 'white', textDecoration: 'none'}} href="https://beian.miit.gov.cn/"> 备案信息： 京ICP备19037543号-2</a>
        </div>
       
      </div>
    </div>
  );
}

export default App;
